*{
  font-family: Impact;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}



@font-face {
  font-family: 'Impact';
  font-style: normal;
  font-weight: normal;
  src: local('IMPACT.TTF'),
  url('./fonts/IMPACT.TTF') format('truetype');
}

video{
  width: 100%;
  height: 800px;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  
}

@media screen and (max-width: 960px) {
  video{
    height: 400px;
  }
}
  @media screen and (max-width: 1260px) {
    video{
      height: 400px;
    }
    
}
@media screen and (max-width: 400px) {
  video{
    height: 280px;

  }
}